import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout
    header={ <Header title="Tags" /> }
    content={
      <section className="section">
        <Helmet title={`Tags | ${title}`} />
        <div className="container content">

          {group.map( tag => (
            <Link
              key={tag.fieldValue}
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
            >
              <Chip
                avatar={<Avatar>{tag.totalCount}</Avatar>}
                label={tag.fieldValue}
                clickable
                style={{margin: 6}}
              />
            </Link>
          ))}

        </div>
      </section>
    }
  />
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
